import PageRoutes from './pages/PageRoutes';
import './App.css';

function App() {
  return (
    <PageRoutes />
  );
}

export default App;
